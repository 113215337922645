import React, { useMemo } from 'react';
import type { Address as AddressType } from '@wilm/shared-types/account/Address';
import Typography from 'components/commercetools-ui/atoms/typography';
import type { TypographyProps } from 'components/commercetools-ui/atoms/typography/types';
import { useFormat } from 'helpers/hooks/useFormat';

interface AddressProps {
    address: AddressType | undefined;
    isShipping?: boolean;
}

const B2BAddress: React.FC<AddressProps> = ({ address, isShipping }) => {
    const { formatMessage } = useFormat({ name: 'checkout' });
    const label = useMemo(() => {
        return !isShipping
            ? formatMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' })
            : formatMessage({ id: 'shippingAddress', defaultMessage: 'Shipping Address' });
    }, [formatMessage, isShipping]);

    if (!address) return <></>;

    const addressInfoTypographyProps: TypographyProps = {
        className: 'text-md lg:text-base mb-4'
    };

    const fullName = `${address.firstName ?? ''} ${address.lastName ?? ''}`.trim();

    const addressInfoTypographyElements = [
        `${fullName}`,
        address.phone,
        `${address.streetName}, ${address.additionalStreetInfo ?? ''}`,
        `${address.postalCode ?? ''} ${address.city}`
    ];

    return (
        <div className="rounded-md border border-checkout-step-border px-15 py-13">
            <p className="mb-8 text-md font-bold">{label}</p>
            {addressInfoTypographyElements.map(element => (
                <Typography key={element} {...addressInfoTypographyProps}>
                    {element}
                </Typography>
            ))}
        </div>
    );
};

export default B2BAddress;
