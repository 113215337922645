import React, { useMemo, useState, useCallback } from 'react';
import Radio from 'components/commercetools-ui/atoms/radio';
import Typography from 'components/commercetools-ui/atoms/typography';
import DeleteModal from 'components/commercetools-ui/organisms/account/sections/addresses/deleteModal';
import AddressModal from 'components/commercetools-ui/organisms/checkout/components/address-modal';
import Icon from 'components/icons/edit';
import TrashIcon from 'components/icons/trash';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import useMappers from '../../hooks/useMappers';
import type { Address } from '../../types';

interface Props {
    className?: string;
    type: 'shipping' | 'billing';
    selectedAddress?: Address;
    onSelectAddress: (address: Address) => void;
    hasError?: boolean;
}

const AccountAddresses: React.FC<Props> = ({ className = '', type, onSelectAddress, selectedAddress, hasError }) => {
    const { formatMessage } = useFormat({ name: 'common' });

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const closeEditModal = useCallback(() => setIsEditModalOpen(false), []);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [editedAddressId, setEditedAddressId] = useState('');
    const [editedAddress, setEditedAddress] = useState({} as Address);

    const { shippingAddresses, billingAddresses, removeAddress } = useAccount();

    const { accountAddressToAddress } = useMappers();

    const addresses = useMemo(() => {
        return type === 'shipping' ? shippingAddresses.slice().reverse() : billingAddresses.slice().reverse();
    }, [type, shippingAddresses, billingAddresses]);
    const closeModal = () => {
        setIsDeleteModalOpen(false);
    };

    const handleDeleteClick = (e: React.MouseEvent, addressId: string) => {
        e.stopPropagation();
        setIsDeleteModalOpen(true);
        setEditedAddressId(addressId);
    };

    const handleEditClick = (e: React.MouseEvent, address: Address) => {
        e.stopPropagation();
        setIsEditModalOpen(true);
        setEditedAddress(address);
        setEditedAddressId(address.addressId);
    };

    const handleDelete = async () => {
        setLoadingDelete(true);

        await removeAddress(editedAddressId);
        setLoadingDelete(false);
        closeModal();
    };

    return (
        <div className={`grid grid-cols-1 flex-col gap-20 md:grid-cols-2 xl:grid-cols-3 ${className}`}>
            {addresses
                .map(address => accountAddressToAddress(address))
                .map(address => (
                    <div key={address.addressId} className="relative">
                        <button
                            className={`border-checkout-border flex w-full cursor-pointer items-center gap-15 rounded-md border p-15 pr-11 lg:py-30 ${address.addressId === selectedAddress?.addressId && hasError ? 'border-red-500' : 'border-checkout-border'}`}
                            onClick={() => {
                                onSelectAddress(address);
                            }}
                            onKeyDown={() => {}}
                            data-cy="shipping-address"
                        >
                            <Radio className="shrink-0" checked={address.addressId === selectedAddress?.addressId} name={type} />

                            <div className="w-[calc(100%-110px)] overflow-hidden text-secondary-black">
                                <p className="truncate text-left text-14">
                                    {address?.firstName} {address?.lastName}
                                    <span className="mt-4 block" />
                                    {address.line1}
                                    <span className="mt-4 block" />
                                    {address.postalCode} {address.city}
                                </p>
                            </div>
                        </button>
                        <div className="absolute right-16 top-[calc(50%-16px)] flex">
                            <button className="flex p-4 hover:cursor-pointer hover:opacity-70" onClick={e => handleEditClick(e, address)}>
                                <Icon className="size-20 text-secondary-black" />
                                <Typography className="sr-only" as="span">
                                    {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
                                </Typography>
                            </button>
                            <button
                                className="flex p-4 hover:cursor-pointer hover:opacity-70"
                                onClick={e => handleDeleteClick(e, address.addressId)}
                            >
                                <TrashIcon className="size-20 text-secondary-black" />
                                <Typography className="sr-only" as="span">
                                    {formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
                                </Typography>
                            </button>
                        </div>
                    </div>
                ))}
            <DeleteModal modalIsOpen={isDeleteModalOpen} loading={loadingDelete} closeModal={closeModal} handleDelete={handleDelete} />
            <AddressModal isOpen={isEditModalOpen} closeModal={closeEditModal} address={editedAddress} />
        </div>
    );
};

export default AccountAddresses;
